import { Box, Typography } from "@mui/material";
import React from "react";

const LabelValue = ({ label, value, onClick = undefined,wrap = false }) => {
  return (
    <>
      <Typography
        color="textPrimary"
        // variant="subtitle2"
        fontWeight={700}
        fontSize="0.75rem"
      >
        {label}
      </Typography>
      <Typography
        color="textPrimary"
        // variant="subtitle2"
        fontWeight={700}
        fontSize="1rem"
        
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: wrap?"wrap":"nowrap",
          cursor: onClick ? "pointer" : "text",
        }}
        onClick={onClick}
      >
        {value || "-"}
      </Typography>
    </>
  );
};

export default LabelValue;

export const PENDING = 'Pending'
export const SUPER_ADMIN = "superadmin"
export const ADMIN = "admin"
export const ADMIN_CASEMANAGER = "admin+casemanager"
export const ADMIN_CASEWORKER ="admin+caseworker"
export const CASEWORKER = "caseworker"
export const CASEMANAGER = "casemanager"
export const VIEW_ONLY = "viewonly"
export const PARENT = "parent"
export const UNASSIGNED = "unassigned"
export const PARENT_ROLE_ID ='8'

export const MIRACLE = "1"
export const GOVT_CCI = "2"
export const GOVT_ORG = "3"
export const NGO_PARTNER = "4"
export const PRIVATE_CCI = "5"

export const INDIA_DB = ['1']
export const INDIA = 'india'
export const USA ='usa'

export const COUNTRY_ID_INDIA = "1"
export const COUNTRY_ID_USA = "2"
export const COUNTRY_ID_UGANDA = "3"


export const Admin = 'Admin'
export const FosterShare = 'FosterShare'
export const ThriveScale = 'Thrive Scale'
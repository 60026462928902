import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/system';
//import Footer from './Components/Footer';
import MainNavbar from './Components/MainNavbar';
import MainSidebar from './Components/MainSidebar';
import { CommonDataContext } from '../../common/contexts/CommonDataContext';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: '#1D334B',
  minHeight: '100vh',
}));

const MainLayout = ({ children }) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
	const navigate = useNavigate()
	const { getDepricationData } = useContext(CommonDataContext);

  useEffect(() => {
    checkAppDeprication()
  }, []);

  const checkAppDeprication = async () => {
    try {
      getDepricationData().then(res => {
        if (res?.type === 'APP_DEPRICATED') {
          navigate('/maintenance');
        }
      })
    } catch (err) {
      console.log("Something went wrong");
    }
  };

  return (
    <MainLayoutRoot>
      {/* <MainNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} /> */}
      <MainSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      {children || <Outlet />}
      {/* <Footer /> */}
    </MainLayoutRoot>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;

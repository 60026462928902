
const Logo = (props) => (
  <img
    alt="Logo"
    src="/static/thrivescaleLogo.png"
    width={250}
    style={{ marginLeft: "-25px" }}
  />
);

export default Logo;

import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, Grid, Modal, Typography } from "@mui/material";
// import {
//   Modal,
//   ModalBody,
//   ModalFooter,
//   ModalHeader,
//   ModalTitle,
// } from 'react-bootstrap';
import { createPortal } from "react-dom";
import CustomEventService from "./customEventService";

export const ModalService = {
  open: (component, modalProps = {}) => {
    CustomEventService.dispatch("modal-open", {
      component,
      config: { ...modalProps },
    });
  },
  close: (id) => {
    CustomEventService.dispatch("modal-close", id);
  },
};

export default function ModalRoot() {
  const [modalList, setModalList] = useState([]);

  const closeModal = (id) => {
    setModalList((prev) => {
      if (id) prev = prev.filter((i) => i.id !== id);
      else prev.pop();
      return [...prev];
    });
  };

  useEffect(() => {
    const openModal = (e) => {
      setModalList((prev) => [...prev, e.detail]);
    };
    CustomEventService.on("modal-open", openModal);
    CustomEventService.on("modal-close", (e) =>
      closeModal(e?.detail?.data?.id)
    );
    const handleUrlChange = () => {
      closeModal();
    };
    window.addEventListener("popstate", handleUrlChange);
    return () => {
      CustomEventService.off("modal-open", openModal);
      CustomEventService.off("modal-close", (e) =>
        closeModal(e?.detail?.data?.id)
      );
      window.removeEventListener("popstate", handleUrlChange);
    };
  }, []);
  return (
    <>
      {Array.isArray(modalList) &&
        modalList.length > 0 &&
        modalList.map((modalData) => {
          const {
            modalHeader,
            modalTitle,
            modalDescription,
            hideModalFooter,
            actionButtonText = "Submit",
            cancelButtonText = "Cancel",
            hideActionButton = false,
            onClick,
            width,
            onHide = () => closeModal(modalData?.id),
            ...restProps
          } = modalData.data.config;
          const style = {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width || "30%",
            minWidth: 300,
            bgcolor: "background.paper",
            borderRadius: 1,
            border: "none",
            outline: "none",
            boxShadow: 24,
            p: 4,
          };
          // const style = {
          //   position: "fixed",
          //   top: "50%",
          //   left: "50%",
          //   transform: "translate(-50%, -50%)", // Remove transform for better responsiveness
          //   width: width || "40vw", // Use vw for width
          //   maxHeight: "80vh", // Use vh for max height
          //   overflowY: "auto",
          //   minWidth: "300px", // Keep minWidth for smaller screens
          //   backgroundColor: "background.paper",
          //   borderRadius: 1,
          //   border: "none",
          //   outline: "none",
          //   boxShadow: 24,
          //   padding: 3,
          // };
          return createPortal(
            <>
              <Backdrop
                sx={{
                  zIndex: (theme) => theme.zIndex.modal - 1,
                  backdropFilter: "blur(4px)",
                }}
                open={true}
              />
              <Modal
                key={modalData?.id}
                open={true}
                onClose={onHide}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                // hideBackdrop
                disableEscapeKeyDown
                slots={{ backdrop: Backdrop }}
                sx={{
                  "& > .MuiBackdrop-root": {
                    backdropFilter: "blur(4px)",
                  },
                }}
                hideBackdrop
                {...restProps}
              >
                <Box sx={style}>
                  {modalTitle && (
                    <Typography
                      id="modal-modal-title"
                      fontSize="1.25rem"
                      fontWeight={700}
                      lineHeight="125%"
                    >
                      {modalTitle}
                    </Typography>
                  )}
                  {modalDescription && (
                    <Typography id="modal-modal-description" my={2}>
                      {modalDescription}
                    </Typography>
                  )}
                  {typeof modalData?.data?.component === "function"
                    ? modalData?.data?.component?.(
                        // onSubmitHandler: () => closeModal(modalData?.id),
                        {
                          close: () => closeModal(modalData?.id),
                        }
                      )
                    : modalData?.data?.component}
                  {!hideModalFooter && (
                    <Grid container spacing={2}>
                      <Grid item xs={hideActionButton ? 12 : 6}>
                        <Button
                          sx={{ mr: 1, borderRadius: "4px", width: "100%" }}
                          variant={hideActionButton ? "contained" : "outlined"}
                          onClick={onHide}
                        >
                          {cancelButtonText}
                        </Button>
                      </Grid>
                      {!hideActionButton && (
                        <Grid item xs={6}>
                          <Button
                            sx={{ mr: 1, borderRadius: "4px", width: "100%" }}
                            variant="contained"
                            onClick={() => {
                              onHide();
                              onClick();
                            }}
                          >
                            {actionButtonText}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Box>
              </Modal>
            </>,
            document.body
          );
          // <Modal
          //   key={modalData?.id}
          //   show={true}
          //   onHide={() => closeModal(modalData?.id)}
          //   {...restProps}
          // >
          //   {(modalHeader || modalTitle) && (
          //     <ModalHeader {...modalHeader}>
          //       {modalTitle && <ModalTitle>{modalTitle}</ModalTitle>}
          //     </ModalHeader>
          //   )}
          //   <ModalBody>
          //     {typeof modalData?.data?.component === 'function'
          //       ? modalData?.data?.component?.({
          //           close: () => closeModal(modalData?.id),
          //         })
          //       : modalData?.data?.component}
          //   </ModalBody>
          //   {modalFooter && <ModalFooter>{modalFooter}</ModalFooter>}
          // </Modal>
        })}
    </>
  );
}

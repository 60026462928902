const LogoSideBar = (props) => (
  <img
    alt="Logo"
    src="/static/nav_logo.svg"
    width={200}
    height="auto"
    style={{ alignSelf: "center" }}
    {...props}
  />
);

export default LogoSideBar;
import moment from "moment-timezone";
import { CommonDataContext } from "./common/contexts/CommonDataContext";
import { useContext } from "react";

export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const GenderList = ["FEMALE", "MALE", "OTHER", "PREFER_NOT_TO_DISCLOSE"];

export const DateFormat = "MM/DD/YYYY";

export const dateFormatter = (data, month = "numeric") => {
  if (data) {
    return new Date(fromUtc(data))?.toLocaleString("en-us", {
      day: "2-digit",
      month: month,
      year: "numeric",
      // hour12: true,
      //hour: '2-digit', // numeric, 2-digit
      //minute: '2-digit', // numeric, 2-digit
      //second: '2-digit', // numeric, 2-digit
    });
  }
};

export const timeFormatter = (data) => {
  if (data) {
    return new Date(fromUtc(data))?.toLocaleString("en-us", {
      // day: "2-digit",
      // month: "numeric",
      // year: "numeric",
      hour12: true,
      hour: "2-digit", // numeric, 2-digit
      minute: "2-digit", // numeric, 2-digit
      second: "2-digit", // numeric, 2-digit
    });
  }
};

export const dateTimeFormatter = (data, month = "numeric") => {
  if (data) {
    return new Date(fromUtc(data))?.toLocaleString("en-us", {
      day: "2-digit",
      month: month,
      year: "numeric",
      hour12: true,
      hour: "2-digit", // numeric, 2-digit
      minute: "2-digit", // numeric, 2-digit
      second: "2-digit", // numeric, 2-digit
    });
  }
};

export function formatTime(timeString) {
  if (!timeString) return ""; // If input is empty, return empty string

  const date = new Date(timeString); // Parse the date string
  if (isNaN(date.getTime())) return timeString; // Check if the date is valid

  const hours = String(date.getHours()).padStart(2, "0"); // Get hours
  const minutes = String(date.getMinutes()).padStart(2, "0"); // Get minutes
  const seconds = String(date.getSeconds()).padStart(2, "0"); // Get seconds

  return `${hours}:${minutes}:${seconds}`; // Return formatted time
}

export function formatTimeForMapping(timeString) {
  // Extract hours, minutes, and seconds from the time string
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Create a new Date object with the desired date and time
  const date = new Date();

  // Get the regional offset in minutes
  const regionalOffsetMinutes = date.getTimezoneOffset();

  // Adjust the time based on the regional offset
  date.setUTCMinutes(date.getUTCMinutes() + regionalOffsetMinutes);
  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);
  date.setUTCSeconds(seconds);

  // Format the Date object to a datetime string without milliseconds
  const datetimeString = date.toISOString().slice(0, -5);
  return datetimeString;
}

export function convertTimeToAMPM(timeString) {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(":")?.map(Number);

  // Create a new Date object with the given time
  const date = new Date();
  date.setHours(hours, minutes, seconds);

  // Get the hours and minutes in AM/PM format
  let hoursInAMPM = date.getHours();
  const minutesString = String(date.getMinutes()).padStart(2, "0");
  const ampm = hoursInAMPM >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hoursInAMPM = hoursInAMPM % 12 || 12;

  // Construct the formatted time string
  const formattedTime = `${hoursInAMPM}:${minutesString} ${ampm}`;

  return formattedTime;
}

export const getDayFromDate = (value) => {
  // Create a Date object from the given date string
  const date = new Date(value);

  // Array to map numeric day of week to its name
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Get the day of the week as a number (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeekNumber = date.getDay();

  // Get the name of the day of the week
  const dayOfWeek = daysOfWeek[dayOfWeekNumber];
  return dayOfWeek;
};

export const convertUnderscoreToText = (input) => {
  if (!input) return ""; // Return empty string if input is null, undefined, or empty

  // Remove underscores and split the string into words
  const words = input.split("_");

  // Capitalize the first letter of the first word
  const firstWord =
    words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

  // Capitalize the first letter of subsequent words and join them with spaces
  const restWords = words
    .slice(1)
    .map((word) => word.charAt(0).toLowerCase() + word.slice(1).toLowerCase())
    .join(" ");

  // Combine the first word and the rest of the words
  return firstWord + " " + restWords;
};

export const convertUnderscoreToTextWithAnd = (input) => {
  if (!input) return ""; // Return empty string if input is null, undefined, or empty

  // Split the string by underscores
  const words = input.split("_");

  // Capitalize the first letter of the first word
  const firstWord =
    words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

  // Capitalize the first letter of subsequent words
  const restWords = words
    .slice(1)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" and ");

  // Combine the first word and the rest of the words
  return firstWord + (restWords ? " and " + restWords : "");
};

export const formatText = (originalString) => {
  let convertedString =
    originalString.charAt(0).toUpperCase() +
    originalString.slice(1).toLowerCase();
  return convertedString;
};

export const combineTimeDate = (date, time) => {
  const parsedDate = new Date(date);
  const year = parsedDate.getFullYear();
  const month = parsedDate.getMonth() + 1;
  const day = parsedDate.getDate();

  let combinedTime = new Date(
    year,
    month - 1,
    day,
    time.split(":")[0],
    time.split(":")[1],
    time.split(":")[2]
  );
  return combinedTime;
};

export function toUtc(value) {
  // Get the browser's current time zone
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a moment object from the time and time zone
  const momentObj = moment.tz(value, timeZone);

  // Convert to UTC
  const utcTime = momentObj.utc();

  return utcTime;
}

export function fromUtc(utcTime) {
  // Get the browser's current time zone
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a moment object from the UTC time
  const momentObj = moment.utc(utcTime);

  // Convert to local time
  const localTime = momentObj.tz(timeZone);

  return localTime;
}

export const FilterKeywords = {
  placementStatus: "Placement status",
  familyStatus: "Family status",
  IN_FOSTER_PLACEMENT: "In foster placement",
  DISCHARGED: "Discharged",
  caseManager: "Case manager",
  status: "Status",
  MPSystemMessageTypeId: "Message type",
  from: "From",
  to: "To",
  active_pending: "Active and pending",
};

export const DateFormatFromRegion = () => {
  const { locationList } = useContext(CommonDataContext);

  const userRegion = locationList.find(
    (location) => location.id === localStorage.getItem("userRegion")
  );

  if (userRegion?.isoCode === "US")
    switch (userRegion?.isoCode) {
      case "US":
        return "MM/DD/YYYY";

      case "UGN":
        return "DD/MM/YYYY";

      case "IND":
        return "DD/MM/YYYY";

      default:
        return "MM/DD/YYYY";
    }
};

export const DateTimeFormatFromRegion = () => {
  const { locationList } = useContext(CommonDataContext);

  const userRegion = locationList.find(
    (location) => location.id === localStorage.getItem("userRegion")
  );

  if (userRegion?.isoCode === "US")
    switch (userRegion?.isoCode) {
      case "US":
        return "MM/DD/YYYY hh:mm a";

      case "UGN":
        return "DD/MM/YYYY hh:mm a";

      case "IND":
        return "DD/MM/YYYY hh:mm a";

      default:
        return "MM/DD/YYYY hh:mm a";
    }
};

export const DaysInWeek = [
  { day: "Monday", label: "MON" },
  { day: "Tuesday", label: "TUE" },
  { day: "Wednesday", label: "WED" },
  { day: "Thursday", label: "THU" },
  { day: "Friday", label: "FRI" },
  { day: "Saturday", label: "SAT" },
  { day: "Sunday", label: "SUN" },
];

// Utility function to strip HTML tags and get plain text
export const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

export const filterMessagesByCurrentTime = (messages) => {
  const now = new Date(); // Get the current time

  // Ensure messages is an array before filtering
  if (!Array.isArray(messages)) {
    console.error(
      "The messages parameter is not an array or is undefined:",
      messages
    );
    return []; // Return an empty array to avoid further errors
  }

  return messages.filter((message) => {
    const startDateTime = new Date(message.startDateTime);
    const endDateTime = new Date(message.endDateTime);

    // Check if the current time is between startDateTime and endDateTime
    return now >= startDateTime && now <= endDateTime;
  });
};

export const DateTimeFormatStringNumaric = (isoString) => {
  if (isoString) {
    return new Date(fromUtc(isoString))?.toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour12: true,
      hour: "2-digit", // numeric, 2-digit
      minute: "2-digit", // numeric, 2-digit
      // numeric, 2-digit
    });
  }
};

export const toSentenceCase = (str) => {
  const removeUnderScore = str?.replace(/_/g, " ");
  if (removeUnderScore) {
    return (
      removeUnderScore?.charAt(0).toUpperCase() +
      removeUnderScore?.slice(1).toLowerCase()
    );
  } else {
    return "Any";
  }
};

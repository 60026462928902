import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLocation, useNavigate, useParams } from "react-router";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CareGiverForm from "./CareGiverForm";
import CheckIcon from "@mui/icons-material/Check";
import HouseholdAndAgencyDetailForm from "./HouseholdAndAgencyDetailForm";
import APIS from "../../../../common/hooks/UseApiCalls";
import { CommonDataContext } from "../../../../common/contexts/CommonDataContext";
import { getStateList } from "../../../../helpers/helperFunction";
import ChevronRightIcon from "../../../../assets/icons/ChevronRight";

const FamilyDetailForm = () => {
  useEffect(() => {
    document.title = "Families | ThriveWell";
  }, []);

  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const { id } = useParams();

  const [familyData, setFamilyData] = useState();

  useEffect(() => {
    if (id) getFamilyDetailsHandler();
    return () => {};
  }, [id]);

  const getFamilyDetailsHandler = async () => {
    try {
      const res = await APIS.getFsFamily(id);
      if (res?.status === 200) {
        setFamilyData(res.data?.data);
      }
    } catch (error) {}
  };

  const { locationList } = useContext(CommonDataContext);
  useEffect(() => {
    if (familyData && locationList.length) {
      patchData();
    }
  }, [familyData, locationList]);

  const patchData = () => {
    let householdAndAgencyData = {
      firstName: familyData.firstName,
      lastName: familyData.lastName,
      casemanagerId: {
        id: familyData.casemanagerId,
        firstName: familyData.casemanagerFirstName,
        lastName: familyData.casemanagerLastName,
        FSCountryId: familyData.FSCountryId,
      },
      zipCode: familyData.zipCode,
      city: familyData.city,
      stateId: familyData.FSStateId,
      primaryLanguage: familyData.primaryLanguage,
      licenceNumber: familyData.licenceNumber,
      address: familyData.address,
      id: familyData.familyId,
      DateStartedasFP:familyData?.DateStartedasFP
    };
    setHouseholdAndAgencyInfo(householdAndAgencyData);
    let careGiverData = [
      {
        id: familyData.parentId,
        firstName: familyData.firstName,
        lastName: familyData.lastName,
        email: familyData.email,
        phoneNumber: familyData.phoneNumber,
        occupation: familyData.occupation,
      },
    ];
    if (familyData?.secondaryParents) {
      let secondaryParent = familyData?.secondaryParents;
      careGiverData.push({
        firstName: secondaryParent.firstName,
        lastName: secondaryParent.lastName,
        email: secondaryParent.email,
        phoneNumber: secondaryParent.phoneNumber,
        occupation: secondaryParent.occupation,
        id: secondaryParent.id,
      });
    }

    setCaregiverInfo({ parents: careGiverData });
  };

  const [isCareGiverInfo, setIsCareGiverInfo] = useState(false);

  const [caregiverInfo, setCaregiverInfo] = useState();
  const [householdAndAgencyInfo, setHouseholdAndAgencyInfo] = useState();

  const { state: locationState } = useLocation();
  useEffect(() => {
    if (locationState?.data) {
      setCaregiverInfo({ parents: locationState.data.parents });
      setHouseholdAndAgencyInfo(locationState.data.householdAgencyData);
      setIsCareGiverInfo(true);
    }
  }, [locationState]);

  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return (
    <Box m={2}>
      <Box
        sx={{ display: "flex", gap: 1, alignItems: "center", flexWrap: "wrap" }}
        mb={2}
      >
        <Typography
          color="textPrimary"
          fontSize="1.5rem"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/fostershare/dashboard")}
          fontWeight={700}
        >
          FosterShare
        </Typography>
        <ChevronRightIcon color="disabled" fontSize="small" />
        <Typography
          color="textPrimary"
          // variant="subtitle2"
          fontWeight={700}
          fontSize="1.5rem"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/fostershare/families")}
        >
          {t("common:common.Families")}
        </Typography>
        <ChevronRightIcon color="disabled" fontSize="small" />
        <Typography
          color="textPrimary"
          // variant="subtitle2"
          fontWeight={700}
          fontSize="1.5rem"
          sx={{ pointerEvents: "none" }}
        >
          {!id
            ? t("common:family.Add a new family")
            : t("common:family.Update family")}
        </Typography>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container>
          <Grid item md={9}>
            <Card>
              <CardContent sx={{ p: 3 }}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography
                      color="textPrimary"
                      // variant="subtitle2"
                      fontWeight={500}
                      fontSize="1rem"
                      sx={{
                        pointerEvents: "none",
                        backgroundColor: !isCareGiverInfo
                          ? "#f37123"
                          : "#9CADB8",
                        color: "#fff",
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!isCareGiverInfo ? 1 : <CheckIcon />}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      // variant="subtitle2"
                      fontWeight={700}
                      fontSize="1rem"
                      sx={{
                        pointerEvents: "none",
                        "@media (max-width:600px)": {
                          // Hide on screens smaller than 600px (xs)
                          display: "none",
                        },
                      }}
                      textAlign={"center"}
                    >
                      {t("Household and agency details")}
                    </Typography>
                  </Box>

                  <Divider sx={{ width: 100, margin: "10px 0" }} />

                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography
                      color="textPrimary"
                      // variant="subtitle2"
                      fontWeight={500}
                      fontSize="1rem"
                      sx={{
                        pointerEvents: "none",
                        backgroundColor: isCareGiverInfo
                          ? "#f37123"
                          : "#9CADB8",
                        color: "#fff",
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      2{" "}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      // variant="subtitle2"
                      fontWeight={700}
                      fontSize="1rem"
                      sx={{
                        pointerEvents: "none",
                        "@media (max-width:600px)": {
                          // Hide on screens smaller than 600px (xs)
                          display: "none",
                        },
                      }}
                      textAlign={"center"}
                    >
                      {t("Caregiver information")}
                    </Typography>
                  </Box>
                </Box>
                {isCareGiverInfo ? (
                  <Typography
                    color="textPrimary"
                    // variant="subtitle2"
                    fontWeight={700}
                    fontSize="1.25rem"
                    sx={{ pointerEvents: "none" }}
                    my={2}
                  >
                    {t("Caregiver information")}
                  </Typography>
                ) : (
                  <Typography
                    color="textPrimary"
                    // variant="subtitle2"
                    fontWeight={700}
                    fontSize="1.25rem"
                    sx={{ pointerEvents: "none" }}
                    my={2}
                  >
                    {t("Household and agency details")}
                  </Typography>
                )}
                <Grid container spacing={2}>
                  {isCareGiverInfo && (
                    <CareGiverForm
                      setIsCareGiverInfo={setIsCareGiverInfo}
                      setCaregiverInfo={setCaregiverInfo}
                      caregiverInfo={caregiverInfo}
                      householdAndAgencyInfo={householdAndAgencyInfo}
                    />
                  )}
                  {!isCareGiverInfo && (
                    <HouseholdAndAgencyDetailForm
                      setIsCareGiverInfo={setIsCareGiverInfo}
                      setHouseholdAndAgencyInfo={setHouseholdAndAgencyInfo}
                      householdAndAgencyInfo={householdAndAgencyInfo}
                      familyActive={familyData?.isActive}
                    />
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Box>
  );
};

export default FamilyDetailForm;

import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const Loader = ({ loading }) => {
    return (
        <>
            <Backdrop
                sx={{
                    zIndex: 9999,
                    color: '#fff',
                    backdropFilter: 'blur(5px)',
                }}
                open={loading}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </>)
}

export default Loader